$(document).ready(function(){
	$(window).load(function(){
		// VARIABLES
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.main-header').outerHeight(),
		$footerHeight = $('footer.footer').outerHeight(),
		$ajaxUrl = $('.ajaxlocation').data('ajaxlocation'),
		$mainWrapper = $('.wrapper-holder');

		// CUSTOM FUNCTIONS
		tableClass();
		mobileLayout();
		wrapperHolder( $pageHeight, $navHeight, $footerHeight );
		cf7formsubmit();
		caretMenu();
		locationBtn();
		newsModal($ajaxUrl);
		aboutTab($ajaxUrl);
		aboutSelect($ajaxUrl);
		objLightcase();
		mySlider();
		$('.loader-overlay').fadeOut(200);
		$('.location-left, .home-news-container, .product-second-download ul').mCustomScrollbar({
			scrollInertia: "500"
		});
	});

	$(window).on('resize', function(){
		// VARIABLES
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.main-header').outerHeight(),
		$footerHeight = $('footer.footer').outerHeight(),
		$mainWrapper = $('.wrapper-holder');
		wrapperHolder( $pageHeight, $navHeight, $footerHeight );

	});

});
function locationBtn(){
	$(document).on('click', '.location-left-holder', function(){
		if($(this).hasClass('active')){
			$('.location-left-bottom').slideUp();
			$(this).removeClass('active');
		}
		else{
			$('.location-left-holder').removeClass('active');
			$('.location-left-bottom').slideUp();
			$(this).addClass('active');
			$('.location-left-holder.active .location-left-bottom').slideDown();
		}
	});
}
function mySlider(){
	$(".home-slider").slick({
	  autoplay: true,
	  infinite: false,
	  speed: 800,
	  slidesToShow: 1,
	  fade: true,
	  slidesToScroll: 1,
	  arrows: true,
	  dots: false,
	  prevArrow : '<button type="button" class="slick-prev slick-controller fas fa-chevron-left"></button>',
	  nextArrow : '<button type="button" class="slick-next slick-controller fas fa-chevron-right"></button>',
	});
	$(".service-slider").slick({
	  autoplay: true,
	  infinite: false,
	  speed: 800,
	  slidesToShow: 1,
	  slidesToScroll: 1,
	  arrows: true,
	  dots: false,
	  prevArrow : '<button type="button" class="slick-prev slick-controller fas fa-chevron-left"></button>',
	  nextArrow : '<button type="button" class="slick-next slick-controller fas fa-chevron-right"></button>',
	});
}

function newsModal($ajaxUrl){
	$(document).on('click', '.news-container-link',function(e){
			var newsId = $(this).data('myid');
			console.log(newsId);
			var $loader = $('.load-bar');
			$.ajax({
                url : $ajaxUrl,
                type : 'POST',
                dataType: 'json',
                data : {
                    action : 'news_select',
                    post_id : newsId,
                },
                     beforeSend: function(){
                     },
                     success: function(response) {
                         var result  = response.output;
                         $('.news-modal').html(result);
                    }
            });
		});
}
function aboutSelect($ajaxUrl){
	$(document).on('change','.about-child-select',function(e){
		var aboutId = $(this).find(':selected').data('id');
		var $loader = $('.branch-container .loader');
		var $loader = $('.about-content-ajax');
		$.ajax({
                url : $ajaxUrl,
                type : 'POST',
                dataType: 'json',
                data : {
                    action : 'about_tab',
                    post_id : aboutId,
                },
                     beforeSend: function(){
                     	$loader.fadeOut();
                     },
                     success: function(response) {
                         var result  = response.output;
                         $('.about-content-ajax').html(result);
                         $loader.fadeIn();

                    }
            });
		});
}
function aboutTab($ajaxUrl){
	$(document).on('click', '.about-child-btn',function(e){
			$('.about-child-btn').removeClass('active');
			var aboutId = $(this).data('id');
			var $loader = $('.about-content-ajax');
			$(this).addClass('active');
			$.ajax({
                url : $ajaxUrl,
                type : 'POST',
                dataType: 'json',
                data : {
                    action : 'about_tab',
                    post_id : aboutId,
                },
                     beforeSend: function(){
                     	$loader.fadeOut();
                     },
                     success: function(response) {
                         var result  = response.output;
                         $('.about-content-ajax').html(result);
                         $loader.fadeIn();

                    }
            });
		});
}

/* MODERNIZR LAYOUT - This serves as the the media query inside the Javascript */
if( Modernizr.mq('(min-width: 1200px)') ) {
}
else if( Modernizr.mq('(min-width: 992px)') && Modernizr.mq('(max-width: 1199px)') ) {
}
else if( Modernizr.mq('(max-width: 991px)') && Modernizr.mq('(min-width: 768px)')){
}
else{
}
